<template>
    <footer id="footer">
        <p>CABRERAs - Unindo Mercados &copy; 2024</p>
    </footer>
</template>

<script>
export default {
    name: 'FooterComponents'
}
</script>

<style scoped>
#footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 5px;
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    border-top: 2px solid #000;
}
</style>