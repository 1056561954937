export const Data = 
	{
		images:[
	{
		name: 'QSoomos_1',
		url_image: 'img/QSoomos_1.webp',
		paragraph:
			'CABRERAS é uma empresa de comércio eletrônico que conecta exportadores brasileiros com importadores internacionais.',
	},
	{
		name: 'QSoomos_2',
		url_image: 'img/QSoomos_2.jpg',
		paragraph:
			'Atuamos como intermediário eficiente e confiável, facilitando a conexão entre exportadores brasileiros e clientes estrangeiros.',
	},
	{
		name: 'QSoomos_3',
		url_image: 'img/QSoomos_3.jpg',
		paragraph:
			'Nosso modelo de negócio se baseia em criar conexões que proporcionem benefícios significativos para todas as partes envolvidas.',
	}],
	services:[
		{
			title: 'Acesso a mercados',
			url_image: 'img/QOfrecemos_1.jpg',
			paragraph:
				'Estendemos o alcance dos exportadores, permitindo-lhes acessar os mercados do Panamá, Cuba e outros países do Caribe em particular, diversificando suas fontes de renda.',
		},
		{
			title: 'Eficiência operacional',
			url_image: 'img/QOfrecemos_2.jpg',
			paragraph:
				'Reduzimos a complexidade do processo de exportação oferecendo uma plataforma centralizada para todas as etapas, desde a negociação até a entrega.',
		},
		{
			title: 'Variedade de produtos',
			url_image: 'img/QOfrecemos_3.jpg',
			paragraph:
				'Oferecemos acesso a uma ampla gama de produtos alimentícios de alta qualidade provenientes do Brasil, permitindo aos importadores diversificar sua carteira.',
		},
		{
			title: 'Segurança e transparência',
			url_image: 'img/QOfrecemos_4.jpg',
			paragraph:
				'Garantimos a transparência nas negociações, assegurando a qualidade dos produtos e a segurança nas transações financeiras.',
		},
		{
			title: 'Redução de custos',
			url_image: 'img/QOfrecemos_5.jpg',
			paragraph:
				'Ao otimizar o processo de exportação, reduzimos os custos operacionais para ambas as partes, tornando o comércio internacional mais acessível e eficiente.',
		},
		{
			title: 'Cumprimento de normas',
			url_image: 'img/QOfrecemos_6.jpg',
			paragraph:
				'Facilitamos o cumprimento das normas de comércio internacional, proporcionando uma experiência segura e legal tanto para exportadores quanto para importadores.',
		}
	]


}
